import {Pipe, PipeTransform} from '@angular/core'
import {TLanguageCode} from '../i18n/i18n.service'
import {TPaintOptionNumberToNameMap} from '../model/model-types'
import {TPaintProcessValue} from '../services/project-types'
import {
  SettingsItemService,
  TSettingOptionUseCase
} from '../services/settings-item.service'
import {TranslateExtraPipe} from './translate-extra.pipe'

/**
 * fe - "factory extras" - It will use TranslateExtraPipe to translate.
 *
 * default - It will use SettingsItemService to translate.
 */
type TPaintTranslateType = 'fe' | 'default'

@Pipe({
  name: 'paintProcess'
})
/**
 * Helping pipe to transform PaintProcessValue to SettingOptionValue's key.
 * Once that transformation is done, it uses SettingsItemService to actually
 * translate a value.
 * Special case for "fe", factory extras, in which, if the flag is passed,
 * instead of using SettingsItemService to get the i18n text, it will use
 * TranslateExtraPipe
 */
export class PaintProcessPipe implements PipeTransform {
  private readonly translateExtra: TranslateExtraPipe = new TranslateExtraPipe()

  constructor(private readonly settingsItemService: SettingsItemService) {
  }

  transform(
    value: TPaintProcessValue,
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode,
    type: TPaintTranslateType = 'default'
  ): string {
    if (type === 'fe') {
      return this.translateExtra.transform(`${value}`, lc)
    } else {
      return this.settingsItemService.getSettingOption('PaintProcess')
        .getI18n(TPaintOptionNumberToNameMap.get(value), useCase, lc)
    }
  }
}
